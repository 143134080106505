<template>
    <div class="ml-10 mr-10">

        <TitleBar :loading="loading">
            {{ $t("menu.export") }}

            <v-btn
                :loading="loading"
                class="mx-2"
                color="secondary"
                fab
                small
                @click.prevent="dialog = true"
            >
                <v-icon>
                    {{ icons.mdiArchivePlus }}
                </v-icon>
            </v-btn>
        </TitleBar>

        <h1 class="mb-10">
        </h1>

        <List :fields="['status', 'document', 'after', 'before', 'created_at']" api-key="exports"
              api-url="/export"></List>

        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t("export.new_request") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <DatePicker
                                    v-model="date_from"
                                    :disabled="loading"
                                    :label="$t('export.date_from')"
                                ></DatePicker>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <DatePicker
                                    v-model="date_until"
                                    :disabled="loading"
                                    :label="$t('export.date_until')"
                                ></DatePicker>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="request"
                    >
                        {{ $t("export.request") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mdiArchivePlus } from "@mdi/js";
import Accounting from "../helpers/Accounting";
import List from "../components/List";
import DatePicker from "@/components/DatePicker";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Export",
    components: {TitleBar, List, DatePicker},
    data() {
        return {
            icons: {
                mdiArchivePlus
            },
            loading: false,
            dialog: false,
            date_from: null,
            date_until: null
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.export"),
                to: "/export",
                exact: true
            }
        ]);
    },
    methods: {
        request() {
            this.dialog = false;
            this.loading = true;

            this.$store.commit("SET_ALERTS", []);
            Accounting.post("/export", {
                    date_from: this.date_from,
                    date_until: this.date_until
                })
                .then(() => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("export.requested")
                    }]);
                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>

</style>